import { useState, useContext } from 'react'
import { Button, Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { green } from '@mui/material/colors'
import { MdQuestionMark } from "react-icons/md";
import ContactSupportBar from '../navbars/ContactSupportBar'
import { LanguageContext } from '../../../context/languageContext'
import { useStatusContext } from '../../../context/statusContext'
import { useErrorMessageContext } from '../../../context/errorMessageContext'

const HelpButton = () => {
    const [openSupportForm, setOpenSupportForm] = useState(false)
    const { status } = useStatusContext()
    const { addError } = useErrorMessageContext()
    const {
        state: { language },
        dispatch: { translate },
    } = useContext(LanguageContext)

    const handleClickSupportForm = () => {
        if (status.connection !== 'online') {
            return addError(`${translate('error_connection')}`)
        }
        setOpenSupportForm(!openSupportForm)
    }

    return (
        <>
            <Button
                onClick={handleClickSupportForm}
                sx={{
                    backgroundColor: '#f5f5f5',
                    borderColor: 'black',
                    '&:hover': {
                        backgroundColor: green['A100'],
                    },
                }}
                startIcon={<MdQuestionMark color='black' />}
            >
                <Typography
                    variant="body2"
                    color="black"
                    style={{
                        fontWeight: 800,
                    }}
                >
                    {translate('button_support')}
                </Typography>
            </Button>
            <ContactSupportBar
                open={openSupportForm}
                onClose={handleClickSupportForm}
            />
        </>
    )
}

export default HelpButton
