import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material'
import { useAuthContext } from '../../context/authContext'
import { LanguageContext } from '../../context/languageContext'
import { useErrorMessageContext } from '../../context/errorMessageContext'
import { useStatusContext } from '../../context/statusContext'
import ServiceCard from '../components/cards/ServiceCard'
import { cardConfig } from '../utils/cardConfig'
import useSetAcademyUrlLogin from '../hooks/useSetAcademyUrlLogin'
import useUserServiceCards from '../hooks/useConfigServiceCards'
import useRemoveCookiesOnClose from '../hooks/useRemoveCookiesOnClose'
import useDecodeUserToken from '../hooks/useDecodeUserToken'
import { underMaintenance } from '../utils/underMaintenance'
import { ServiceData } from '../types/types.d'
import { useVersion } from '../../context/appVersionContext'
import MainWrapper from '../components/common/MainWrapper'

const Services = () => {
	const { user, addUser, removeUser } = useAuthContext()
	const [cookies, setCookie, removeCookie] = useCookies()
	const { appVersion } = useVersion()
	const { addError } = useErrorMessageContext()
	const { status } = useStatusContext()
	let navigate = useNavigate()

	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const { maintenance, formattedDate } = underMaintenance(language)

	const [userServices, setUserServices] = useState<ServiceData[]>([])

	const [academy, setAcademy] = useState({
		loginURL: '',
	})

	const [chargeBee, setChargeBee] = useState({
		loginURL: '',
	})

	useEffect(() => {
		useSetAcademyUrlLogin(user, status, setAcademy, addError, translate)
	}, [user])

	useEffect(() => {
		const autologin = async () => {
			await useDecodeUserToken(cookies, addUser, addError, navigate, translate)
		}
		autologin()
	}, [])

	useEffect(() => {
		useUserServiceCards(user, academy, cardConfig, chargeBee, setUserServices)
	}, [user, academy])

	useRemoveCookiesOnClose(user, removeUser, cookies, removeCookie)

	const [openMyProfile, setOpenMyProfile] = useState(false)

	const handleClickMyProfile = () => {
		setOpenMyProfile(!openMyProfile)
	}

	const [openSupportForm, setOpenSupportForm] = useState(false)

	const handleClickSupportForm = () => {
		if (status.connection !== 'online') {
			return addError(`${translate('error_connection')}`)
		}
		setOpenSupportForm(!openSupportForm)
	}

	return (
		<Grid sx={{
			flexGrow: 1,
			height: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
		}}
			container
			spacing={3.5}
			alignItems="stretch"
			alignContent="stretch"

		>
			<MainWrapper>
				<Grid item xs={12} sm={12} md={12} lg={8.5} xl={12}>
					<Grid container justifyContent="center" spacing={0}>
						{maintenance && (
							<Alert severity="warning" sx={{ mb: 3, mx: '30%' }}>
								<strong>{`${translate('title_maintenance')}`}</strong>
								<br />
								{`${translate('text_maintenance_pt1')} ${formattedDate}. ${translate(
									'text_maintenance_pt3'
								)}`}
								<br />
								{`${translate('text_maintenance_pt2')}`}
							</Alert>
						)}
						<Box
							sx={{
								mt: {
									xs: maintenance ? 3 : 0,
									sm: maintenance ? 3 : 0,
									md: maintenance ? 3 : '40px',
									lg: maintenance ? 3 : '100px',
									xl: maintenance ? 3 : '100px',
								},
								backgroundColor: 'inherit',
								display: 'grid',
								gridTemplateColumns: {
									xs: '1fr',
									sm: '1fr',
									md: '1fr 1fr',
									lg: '1fr 1fr',
									xl: '1fr 1fr',
								},
								gap: 3,
								width: { xs: 'auto', sm: 'auto', md: 'auto', lg: '50%', xl: '50%' },
								height: '100%',
							}}
						>
							{userServices.length !== 0 &&
								userServices.map((services: ServiceData) => (
									<Box
										key={Math.random().toString().split('.')[1]}
										sx={{
											width: '20vw',
											minWidth: {
												xs: '280px',
												sm: '300px',
												md: '360px',
												lg: '340px',
												xl: '340px',
											},
										}}
									>
										<ServiceCard {...services} />
									</Box>
								))}
						</Box>
					</Grid>
				</Grid>
				<Grid
					container
					direction="row"
					justifyContent="center"
					wrap="nowrap"
					alignItems="flex-end"
					sx={{ mt: '2vh' }}
				>
					<Box
						sx={{
							zIndex: '0 !important',
							width: { md: '100%', lg: '100%', xl: '100%' },
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'stretch',
							alignItems: 'center !important',
						}}
					>
						<Divider
							variant="middle"
							sx={{
								color: '#35e398',
								borderColor: '#35e398',
								width: { xs: '300px', sm: '340px', md: '100%', lg: '45%', xl: '35%' },
								borderWidth: 1,
								marginLeft: {
									xs: '40px',
									sm: '50px',
									md: '50px',
									lg: '0px',
									xl: '0px',
								},
							}}
						/>
						<Typography
							variant="h5"
							sx={{
								mt: 2,
								color: 'white',
								fontSize: {
									xs: '10px',
									sm: '12px',
									md: '24px',
									lg: '24px',
									xl: '24px',
								},
								marginLeft: {
									xs: '30px',
									sm: '30px',
									md: '50px',
									lg: '20px',
									xl: '20px',
								},
								marginX: 1,
								textAlign: 'center',
							}}
						>
							{translate('text_service_pt1')}
						</Typography>
						<Typography
							variant="caption"
							sx={{
								color: 'white',
								fontSize: { xs: '8px', sm: '10px', md: '12px', lg: '12px', xl: '12px' },
								marginLeft: {
									xs: '40px',
									sm: '40px',
									md: '50px',
									lg: '20px',
									xl: '20px',
								},
								marginX: 2,
								textAlign: 'center',
							}}
						>
							{translate('text_service_pt2')}
						</Typography>
					</Box>
				</Grid>
				<Box
					sx={{
						position: {
							xs: 'relative',
							sm: 'relative',
							md: 'absolute',
							lg: 'absolute',
							xl: 'absolute',
						},
						left: { xs: 0, sm: 0, md: 16, lg: 16, xl: 16 },
						bottom: { xs: 0, sm: 0, md: 16, lg: 16, xl: 16 },
						top: { xs: 30, sm: 30, md: 16, lg: 16, xl: 16 },
						display: 'flex',
						justifyContent: {
							xs: 'center',
							sm: 'center',
							md: 'flex-start',
							lg: 'flex-start',
							xl: 'flex-start',
						},
						alignItems: {
							xs: 'center',
							sm: 'center',
							md: 'flex-end',
							lg: 'flex-end',
							xl: 'flex-end',
						},
						width: { xs: '100%', sm: '100%', md: 'auto', lg: 'auto', xl: 'auto' },
						marginLeft: {
							xs: '40px',
							sm: '30px',
							md: '50px',
							lg: '20px',
							xl: '20px',
						},
						marginTop: { xs: '5px', sm: '5px' },
					}}
				>
					<Typography variant="caption" sx={{ color: 'white' }}>
						{appVersion.portal}
					</Typography>
				</Box>
			</MainWrapper>
		</Grid>
	)
}

export default Services
