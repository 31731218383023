import {
	Typography,
	Modal,
	Button,
	IconButton,
	TextField,
	InputAdornment,
	Box,
} from '@mui/material'
import { LanguageContext } from '../../../context/languageContext'
import { useContext, useState } from 'react'
import { green, grey } from '@mui/material/colors'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import LanguageIcon from '@mui/icons-material/Language'
import { useNavigate } from 'react-router-dom'

function LanguageSelectorModal() {
	const navigate = useNavigate()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)
	const [searchTerm, setSearchTerm] = useState('')
	const [open, setOpen] = useState(false)
	const handleOpen = () => setOpen(true)
	const handleClose = () => {
		setOpen(false)
		setSearchTerm('')
	}
	const commonLanguages = [
		{ code: 'ca', label: `${translate(`button_lang_ca`)}` },
		{ code: 'de', label: `${translate(`button_lang_de`)}` },
		{ code: 'en', label: `${translate(`button_lang_en`)}` },
		{ code: 'es-lt', label: `${translate(`button_lang_es-lt`)}` },
		{ code: 'es', label: `${translate(`button_lang_es`)}` },
		{ code: 'eu', label: `${translate(`button_lang_eu`)}` },
		{ code: 'fr', label: `${translate(`button_lang_fr`)}` },
		{ code: 'it', label: `${translate(`button_lang_it`)}` },
		{ code: 'ms', label: `${translate(`button_lang_ms`)}` },
		{ code: 'nl', label: `${translate(`button_lang_nl`)}` },
		{ code: 'pl', label: `${translate(`button_lang_pl`)}` },
		{ code: 'pt', label: `${translate(`button_lang_pt`)}` },
		{ code: 'pt-br', label: `${translate(`button_lang_pt-br`)}` },
		{ code: 'tr', label: `${translate(`button_lang_tr`)}` },
	]
	const devLanguages = [
		{ code: 'test', label: `${translate(`button_lang_test`)}` },
		{ code: 'ids', label: `${translate(`button_lang_ids`)}` },
	]
	const filteredLanguages = (languages) =>
		languages.filter((lang) =>
			lang.label.toLowerCase().includes(searchTerm.toLowerCase())
		)
	const languageHandler = (lang: string) => {
		setLanguage(lang)
		handleClose()
		setSearchTerm('')
		if (lang !== 'test') {
			navigate(0)
		}
	}


	return (
		<>
			<Button
				onClick={handleOpen}
				sx={{
					backgroundColor: '#f5f5f5',
					borderColor: 'black',
					'&:hover': {
						backgroundColor: green['A100'],
					},
				}}
				startIcon={<LanguageIcon sx={{ color: 'black' }} />}
			>
				<Typography
					variant="body2"
					color="black"
					style={{
						fontWeight: 800,
					}}
				>
					{`${translate(`button_lang_${language.toLowerCase()}`)}`}
				</Typography>
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={{
						position: 'absolute' as 'absolute',
						top: {
							xs: '50%',
							sm: '45%',
							md: '35%',
							lg: '35%',
						},
						left: {
							xs: '45%',
							sm: '46%',
							md: '48%',
							lg: '50%',
						},
						transform: 'translate(-50%, -50%)',
						width: {
							xs: '90%',
							sm: '90%',
							md: '70%',
							lg: '70%',
						},
						bgcolor: grey['100'],
						border: '2px solid #000',
						boxShadow: 24,
						p: 4,
						mx: 2,
						borderRadius: '15px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'nowrap',
							justifyContent: 'space-between',
							alignItems: 'center',
							alignContent: 'stretch',
						}}
					>
						<TextField
							placeholder={`${translate(`label_search_language_modal`)}...`}
							inputProps={{ 'aria-label': 'search' }}
							onChange={(e) => setSearchTerm(e.target.value)}
							sx={{
								marginLeft: 4,
								my: 1,
								'& .MuiOutlinedInput-root': {
									borderRadius: '50px',
								},
								'& .MuiOutlinedInput-input': {
									color: grey[700],
									'&:hover fieldset': {
										borderColor: '#b9f6ca',
									},
								},
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon
											sx={{
												fontSize: {
													xs: 20,
													sm: 25,
													md: 30,
													lg: 35,
												},
											}}
											color="secondary"
										/>
									</InputAdornment>
								),
							}}
						/>

						<IconButton
							aria-label="close"
							sx={{
								'&:hover': {
									backgroundColor: '#b9f6ca',
								},
							}}
							onClick={handleClose}
							id="profileForm"
						>
							<CloseIcon
								sx={{
									fontSize: {
										xs: 25,
										sm: 35,
										md: 35,
										lg: 35,
									},
								}}
							/>
						</IconButton>
					</Box>
					<Box
						sx={{
							paddingTop: 4,
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							justifyContent: 'start',
							alignItems: 'start',
							alignContent: 'center',
						}}
					>
						{filteredLanguages(commonLanguages).map((lang) => (
							<Box
								sx={{
									width: {
										xs: '50%',
										sm: '50%',
										md: '20%',
									},
									height: '6vh',
									display: 'flex',
									justifyContent: 'center',
									alignContent: 'center',
								}}
							>
								<Button
									key={lang.code}
									onClick={() => languageHandler(lang.code)}
									sx={{
										height: '4vh',
										backgroundColor: language === lang.code ? green['A100'] : '',
										'&:hover': {
											backgroundColor: '#b9f6ca',
										},
									}}
								>
									<Typography
										variant="body2"
										color="black"
										sx={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											'&:hover': {
												padding: 0,
												backgroundColor: '#b9f6ca',
												borderRadius: '25px',
												zIndex: 10,
												overflow: 'visible',
												whiteSpace: 'nowrap',
												height: 'auto',
											},
										}}
										style={{
											marginLeft: 10,
											marginRight: 10,
											fontWeight: 800,
										}}
									>
										{`${translate(`button_lang_${lang.code.toLowerCase()}`)}`}
									</Typography>
								</Button>
							</Box>
						))}
						{process.env.REACT_APP_MODE === 'development' &&
							filteredLanguages(devLanguages).map((lang) => (
								<Box
									sx={{
										width: {
											xs: '50%',
											sm: '50%',
											md: '20%',
										},
										height: '6vh',
										display: 'flex',
										justifyContent: 'center',
										alignContent: 'center',
									}}
								>
									<Button
										key={lang.code}
										onClick={() => languageHandler(lang.code)}
										sx={{
											height: '4vh',
											backgroundColor: language === lang.code ? green['A100'] : '',
											'&:hover': {
												backgroundColor: '#b9f6ca',
											},
										}}
									>
										<Typography
											variant="body2"
											color="black"
											style={{
												marginLeft: 10,
												marginRight: 10,
												fontWeight: 800,
											}}
										>
											{`${translate(`button_lang_${lang.code.toLowerCase()}`)}`}
										</Typography>
									</Button>
								</Box>
							))}
					</Box>
				</Box>
			</Modal>
		</>
	)
}

export default LanguageSelectorModal
